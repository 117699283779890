body {
  margin: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
body * {
  font-family: "Poppins", sans-serif !important;
  box-sizing: border-box;
}

code {
  font-family: "Poppins", sans-serif;
}

.MuiFormControl-root-MuiTextField-root-root {
  background: "#fff";
}

.css-wb57ya-MuiFormControl-root-MuiTextField-root {
  background: "#fff";
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9d9d9;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f35344;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f74333;
}
